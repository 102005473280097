<template>
  <router-view v-slot="{ Component }">
          <keep-alive>
            <div class="transition">
              <component :is="Component" class="enter-transition" />
            </div>
          </keep-alive>
  </router-view>
</template>

<script>
// import WelcomeScreen from "@/components/WelcomeScreen";
export default {
  name: 'App',
}
</script>

<!--<style>-->
<!--@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');-->
<!--</style>-->
<style>
@font-face {
  font-family:'ABC';
  src:url("./assets/ABC.otf");
}

body {
  margin: 0;
  font-family: 'ABC', cursive,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
/*@font-face {*/
/*  font-family: Future;*/
/*  src: url("./assets/GlowSans.otf");*/
/*}*/

.transition-enter-active {
  transition: all 0.3s ease-out;
}
.transition-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.transition-enter-from,
.transition-leave-to {
  /*transform: translateX(20px);*/
  opacity: 0;
}
.transition-enter-to,.transition-leave-from{
  opacity: 1;
}

.transition{
  animation: fadeIn 0.3s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}



</style>
