<template>
  <div id="navbar">
      <div class="navbar-container">
        <div class="navbar-container-left">
          <router-link to="/">
            <div class="navbar-container-left-logo">
              <img
                  :src="require('../assets/logo.svg')"
                  alt="UXiG Logo"
              >
            </div>
          </router-link>

      </div>
        <div class="navbar-container-right">

        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "NavBar"
}
</script>

<style scoped>
.navbar-container{
  display: flex;
  flex-direction: row;
  padding: 10px;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 20px 20px;
  /*width: 85vw;*/
}
.navbar-container-left{
  margin-top: 0vh;
  margin-left: 2vw;
}
.navbar-container-right{
  margin-left: auto;
}
.navbar-container-left-logo>img{
  width: 90px;
}

@media screen and (max-width: 768px) {
  .navbar-container-left{
    margin-top: 10px;
    margin-left: 0;
  }
  .navbar-container-left-logo>img{
    width: 72px;
  }
}


</style>
