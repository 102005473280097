<template>
  <div class='welcomeScreen'>
    <main-title title="You seems to be lost..." ></main-title>
    <div class='introduction'>
            <span >
              The page "{{currentPath}}" you just tried to go does not exist. Hold on while we put you back on track.
            </span>
    </div>
  </div>
</template>

<script>

import MainTitle from "@/components/MainTitle";

export default {
  name: "WelcomeScreen",
  components: {MainTitle},
  data() {
    return {
      currentPath: this.$route.path
    };
  },
  methods: {
    goHome() {
      this.$router.push({name: 'Home'});
    }
  },
  mounted() {
    setTimeout(() => {
      this.goHome();
    }, 3000);
  }

}



</script>

<style scoped>
.welcomeScreen{
  background-color: rgba(255, 255, 255, 0.65);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding : 60px 100px;
  font-family: 'ABC', cursive, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
@media (max-width: 768px) {
  .welcomeScreen{
    padding : 60px 20px;
  }
}


</style>
