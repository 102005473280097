<template>
  <nav-bar></nav-bar>

  <div class='categoryScreen'>

    <!-- No.1 Choice -->

    <div class="intro-title" >
      <img :src="require('../assets/titleImage/effortless.png')">
      <div class="intro-subtitle" >
        <span>
          By completing these question, we can provide you with recommendation.
        </span>
      </div>
    </div>
    <main-button text="Just show me" style="margin: auto" url="/result"></main-button>

    <div class="introduction">
      <div class="intro-container-left" id="art-intro-container">
        <div class="intro-content">
          <div class="intro-content-image">
            <img
                :src="require('../assets/showcase/sunset.jpg')"
            >
          </div>
          <main-button class="introduction-button" text="Draw Art" color-style="#e63946" @click="() =>{record('Draw Art',1);scrollToBottom();}" ></main-button>
        </div>
      </div>
      <div class="intro-container-left" id="music-intro-container">
        <div class="intro-content">
          <div class="intro-content-image">
            <img
                :src="require('../assets/showcase/casset.jpg')"
            >
          </div>
          <main-button class="introduction-button" text="Make Music" color-style="#1d3557" @click="()=>{record('Make Music',1);scrollToBottom();}"></main-button>
        </div>
      </div>
      <div class="intro-container-left" id="literature-intro-container">
        <div class="intro-content">
          <div class="intro-content-image">
            <img
                :src="require('../assets/showcase/typewriter.jpg')"
            >
          </div>
          <main-button class="introduction-button" text="Write Story" color-style="#ffb703"  @click="()=>{record('Write Story',1);scrollToBottom();}"></main-button>
        </div>
      </div>
    </div>
    <!-- No.2 Choice -->
    <div class="intro-title" >
      <div class="intro-subtitle" >
        <span v-if="textIf[1]">
          Please select the correct option to enable us to help you select a more suitable tool.
        </span>
      </div>
    </div>
     <div class="introduction">
          <main-button v-for="(item, index) in itemsSelect[1]" :key="index" :text="item"  :color-style="this.colorGroup[index]" @click="()=>{record(item,2);scrollToBottom();}" class="introduction-button2" ></main-button>
    </div>

    <!-- No.3 Choice -->
    <div class="intro-title" >
      <div class="intro-subtitle" >
        <span v-if="textIf[2]">
          Please select the correct option to enable us to help you select a more suitable tool.
        </span>
      </div>
    </div>
     <div class="introduction">
          <main-button v-for="(item, index) in itemsSelect[2]" :key="index" :text="item"  :color-style="this.colorGroup[index]" @click="() =>{record(item,3);scrollToBottom();}" class="introduction-button2" ></main-button>
    </div>

    <div class="introduction">
      <main-button class="introduction-button2" text="Results!" color-style="#ced4b2" v-if="ResultButton" @click='GoToResult()'></main-button>
    </div>



  </div>



</template>


<script>
//import MainTitle from "@/components/MainTitle";
//import OptionGroup from "@/components/optionGroup";
import NavBar from "@/components/navbar";
import MainButton from "@/components/MainButton.vue";
export default {
  name: "CategoryView",
  components: { MainButton, NavBar},computed: {
    isMobile: function() {
      //calculate if is mobile
      return window.innerWidth < 768;
    }

  },
  data() {
    return {
      choices:{'Draw Art':['Painting Basics','General Art Assets','Art Inspiration','Specify Generation Targets'],
              'Make Music':['Music Theory Basics','General Music Assets','Music Inspiration'],
              'Write Story':['Special needs','General Text Assets','Story Inspiration'],

              /* 美术资产 */

              'Painting Basics':['General Ideas already','Line drawings already'],
              'General Art Assets':['Graphic Design','Interface Design'],
              'Art Inspiration':['Text already','Pictures already'],
              'Specify Generation Targets':['Monsters','Anime characters','Landscapes'],

              'General Ideas already':['Nvidia GauGAN2'],
              'Line drawings already':['Webtoon AI Painter:漫画AI上色','Nvidia Canvas'],
              'Graphic Design':['Artbreeder','清华CogView','Google Disco Diffusion','Dream:文字+艺术风格=创作'],
              'Interface Design':['Components AI','uibot:界面设计ai'],
              'Text already':['Generative Engine'],
              'Pictures already':['aidraw图片一键变黑白线稿','AI Gahaku:把照片变成杰作'],
              'Monsters':['Chimera Painter:怪兽生成器'],
              'Anime characters':['StyleGAN'],
              'Landscapes':['Nvidia GauGAN2:神笔马良'],

              /* 音乐资产 */

              'Music Theory Basics':['Rhythm Generation','Drum Generation','Stylization'],
              'General Music Assets':['Lyrics Generation','Song Generation'],
              'Music Inspiration':['Blob Opera','Body Synth','Beat Blender','Magenta','Drum Machine'],

              'Rhythm Generation':['Neural Drum Machine'],
              'Drum Generation':['DrumBot'],
              'Stylization':['Assisted Melody'],
              'Lyrics Generation':['灵动音Lyrica','theselyricsdonotexist'],
              'Song Generation':['灵动音BGM猫','VIVA','Boomy','Ecrett Music'],

              /* 文字资产 */

              'Special needs':['Poems','General'],
              'General Text Assets':['Continuation','Creation','Assisted Writing'],
              'Story Inspiration':['Music Basics','For Fun'],

              'Poems':['诗三百','华为“乐府”'],
              'General':['百度PaddlePaddle AI写作'],
              'Continuation':['inferkit'],
              'Creation':['AI Dungeon','Getgetai','彩云小梦','Giiso'],
              'Assisted Writing':['写作猫中文写作助手','ProWritingAid'],
              'Music Basics':['LyricJam'],
              'For Fun':['满分作文生成器','狗屁不通文章生成器']
              },
      ResultButton : false,
      itemsSelect : [[],[],[],[]],
      textIf : [false, false, false, false],
      resultAI : [],
      colorGroup : ["#e63946", "#1d3557", "#ffb703", "#ced4b2"],
      stepNow : 0

  }
  },methods: {
    record(choiceText, Step) {

      console.log(choiceText, Step);

      this.stepNow = Step

      for (let i = this.stepNow; i<4; i++){
        this.textIf[i] = false;
        this.itemsSelect[i] = [];
        this.ResultButton = false;
      }

      if (this.choices[choiceText]){
        if (!this.choices[this.choices[choiceText][0]]){
          this.ResultButton = true;
          this.resultAI = this.choices[choiceText]
          console.log("result=", this.resultAI)
        }
        else{
          this.textIf[this.stepNow] = true;
          this.itemsSelect[this.stepNow] = this.choices[choiceText];
        }
      }else{
        this.ResultButton = true;
      }

      console.log(this.itemsSelect);

    },
    GoToResult() {
      this.$router.push('/result');
    },
    scrollToBottom(){
      console.log('scrollToBottom');
      setTimeout(()=>{
        this.DoScroll();
      })
    },
    DoScroll(){
      (function smoothscroll() {
        const currentScroll = document.documentElement.scrollTop || document.body.scrollTop; // 已经被卷掉的高度
        const clientHeight = document.documentElement.clientHeight; // 浏览器高度
        const scrollHeight = document.documentElement.scrollHeight; // 总高度
        if (scrollHeight - 10 > currentScroll + clientHeight) {
          window.requestAnimationFrame(smoothscroll);
          window.scrollTo(0, currentScroll + (scrollHeight - currentScroll - clientHeight) / 2);
        }
      })();
    }
  }
}


</script>

<style scoped>

.categoryScreen{
  background-color: rgba(255, 255, 255, 0.65);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding : 60px 100px;
  height: 100vh;
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .categoryScreen{
    padding : 60px 20px;
  }

.introduction{
    display: flex;
    flex-direction: column;
    padding: 0px 10vw;
  }
  .intro-container-left{
    margin: auto;
  }
  .intro-content-image>img{
    border: white solid 1px;
    border-radius: 40px;
    width: 260px;
  }
  .header>img{
    width: 600px ;
  }

  .intro-title{
    display: flex;flex-direction: column ;align-items: center; margin-bottom: 40px;
  }
  .intro-title>img{
    max-width: 90vw;margin-bottom: 40px;
  }
  .dedicate-tool-title>img{
    max-width: 90vw;margin-bottom: 40px;

  }
  .game-title>img{
    max-width: 90vw;margin-bottom: 40px;

  }

  .intro-title>.intro-subtitle{
    max-width: 90vw;text-align: center;
  }
  .game-content-image>img{
    max-width: 90vw;
  }

}

.intro-title,.dedicate-tool-title,.game-title{
  display: flex;flex-direction: column ;align-items: center; margin-bottom: 40px;
}
.intro-title>img,.dedicate-tool-title>img,.game-title>img{
  max-width: 480px;margin-bottom: 40px;
}
.intro-title>.intro-subtitle,.dedicate-tool-title>.dedicate-tool-subtitle
,.game-title>.game-subtitle{
  max-width: 60vw;text-align: center;
}

.header{
  display: flex;
  justify-content: center;
  margin-top: 40px;

}
.header>img{
  max-width:100% ;
}

.intro-content{
  position:relative;
  /*width:30vw;*/
}
.introduction-text{
  position: absolute;
  left: 0px;
  bottom: 0px;
}
.introduction-button{
  position: relative;

  left: 40px;
  bottom: 20px;
}

.introduction-button2{
  position: relative;
  top: 0px;
  margin: 0 auto;
}
.intro-content-image>img{
  border: white solid 1px;
  border-radius: 40px;
  width: 180px;
}

.introduction{
  display: flex;
  flex-direction: row;
  padding: 0px 16vw;
  margin-bottom: 120px;
}


.intro-container-left{
  margin-right: auto;
}
.intro-container-right{
  margin-left: auto;
}

.title{
  margin-top: 10vh;
  font-size: 60px;
  /*font-family: 'Major Mono Display', monospace;*/
}
.hero-banner{
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  margin-bottom: 20vh;
}


</style>
