<template>
  <div id="diagram-container">
    <div id="graph-container">
      <div id="mountNode" ></div>
    </div>
  </div>
</template>

<script>
import G6 from '@antv/g6';


let graph;

window.onresize = function() {
  if (graph) {
    const container = document.getElementById('graph-container');
    const width = container.scrollWidth;
    const height = container.scrollHeight || 800;

    graph.changeSize(width, height);
  }
};
export default {
  name: "DiagramViewer",
  mounted() {
    this.graph_init();
  },
  methods:{
    graph_init(){
      G6.registerNode(
          'text-gen',

          {
            // getAnchorPoints() {
            //   return [
            //     [0, 0.5], // 左侧中间
            //     [1, 0.5], // 右侧中间
            //   ];
            // },
            drawShape(cfg, group) {
              const rect = group.addShape('rect', {
                attrs: {

                  x: -75,
                  y: -25,
                  width: 197,
                  height: 75,
                  radius: 10,
                  stroke: '#3F85ED',
                  fill: '#ffffff',
                  lineWidth: 3,
                  cursor: 'pointer',
                },
                name: 'rect-shape',
              });
              group.addShape('image', {
                attrs: {
                  x: -60,
                  y: -19,
                  height: 16,
                  width: 16,

                  img: '/nodeIcon/Invoice.svg',
                },
                name: 'node-icon',
              });
              group.addShape('text', {
                attrs: {
                  text: "Text Generation",
                  x: -40,
                  y: -10,
                  fill: '#3F85ED',
                  fontSize: 12,
                  textAlign: 'left',
                  textBaseline: 'middle',
                  // fontWeight: 'Medium',
                  fontFamily:'Roboto',
                },
                name: 'title',
              });

              group.addShape('text', {
                  attrs: {
                    text: cfg.name,
                    x: -60,
                    y: 15,
                    fill: '#000000',
                    fontSize: 16,
                    textAlign: 'left',
                    textBaseline: 'middle',
                    fontWeight: 'Bold',
                    fontFamily:'ABC',
                  },
                  name: 'text-shape',
                });

              return rect;
            },

          },
          'single-node',
      );
      G6.registerNode(
          'image-gen',
          {
            // getAnchorPoints() {
            //   return [
            //     [0, 0.5], // 左侧中间
            //     [1, 0.5], // 右侧中间
            //   ];
            // },
            drawShape(cfg, group) {
              const rect = group.addShape('rect', {
                attrs: {
                  x: -75,
                  y: -25,
                  width: 197,
                  height: 75,
                  radius: 10,
                  stroke: '#3B3F81',
                  fill: '#ffffff',
                  lineWidth: 3,
                  cursor: 'pointer',
                },
                name: 'rect-shape',
              });
              group.addShape('image', {
                attrs: {
                  x: -60,
                  y: -19,
                  height: 16,
                  width: 16,

                  img: '/nodeIcon/img_gen.svg',
                },
                name: 'node-icon',
              });
              group.addShape('text', {
                attrs: {
                  text: "Image Generation",
                  x: -40,
                  y: -10,
                  fill: '#3B3F81',
                  fontSize: 12,
                  textAlign: 'left',
                  textBaseline: 'middle',
                  // fontWeight: 'Medium',
                  fontFamily:'Roboto',
                },
                name: 'title',
              });

              group.addShape('text', {
                attrs: {
                  text: cfg.name,
                  x: -60,
                  y: 15,
                  fill: '#000000',
                  fontSize: 16,
                  textAlign: 'left',
                  textBaseline: 'middle',
                  fontWeight: 'Bold',
                  fontFamily:'ABC',
                },
                name: 'text-shape',
              });

              return rect;
            },

          },
          'single-node',
      );
      G6.registerNode(
          'audio-gen',
          {
            // getAnchorPoints() {
            //   return [
            //     [0, 0.5], // 左侧中间
            //     [1, 0.5], // 右侧中间
            //   ];
            // },
            drawShape(cfg, group) {
              const rect = group.addShape('rect', {
                attrs: {
                  x: -75,
                  y: -25,
                  width: 197,
                  height: 75,
                  radius: 10,
                  stroke: '#F56C6C',
                  fill: '#ffffff',
                  lineWidth: 3,
                  cursor: 'pointer',
                },
                name: 'rect-shape',
              });
              group.addShape('image', {
                attrs: {
                  x: -60,
                  y: -19,
                  height: 16,
                  width: 16,

                  img: '/nodeIcon/Music.svg',
                },
                name: 'node-icon',
              });
              group.addShape('text', {
                attrs: {
                  text: "Audio Generation",
                  x: -40,
                  y: -10,
                  fill: '#F56C6C',
                  fontSize: 12,
                  textAlign: 'left',
                  textBaseline: 'middle',
                  // fontWeight: 'Medium',
                  fontFamily:'Roboto',
                },
                name: 'title',
              });

              group.addShape('text', {
                attrs: {
                  text: cfg.name,
                  x: -60,
                  y: 15,
                  fill: '#000000',
                  fontSize: 16,
                  textAlign: 'left',
                  textBaseline: 'middle',
                  fontWeight: 'Bold',
                  fontFamily:'ABC',
                },
                name: 'text-shape',
              });

              return rect;
            },

          },
          'single-node',
      );
      G6.registerNode(
          'human-gen',
          {
            // getAnchorPoints() {
            //   return [
            //     [0, 0.5], // 左侧中间
            //     [1, 0.5], // 右侧中间
            //   ];
            // },
            drawShape(cfg, group) {
              const rect = group.addShape('rect', {
                attrs: {
                  x: -75,
                  y: -25,
                  width: 197,
                  height: 75,
                  radius: 10,
                  stroke: '#000000',
                  fill: '#ffffff',
                  lineWidth: 3,
                  cursor: 'pointer',
                },
                name: 'rect-shape',
              });
              group.addShape('image', {
                attrs: {
                  x: -60,
                  y: -19,
                  height: 16,
                  width: 16,

                  img: '/nodeIcon/Asana.svg',
                },
                name: 'node-icon',
              });
              group.addShape('text', {
                attrs: {
                  text: "Human Work",
                  x: -40,
                  y: -10,
                  fill: '#000000',
                  fontSize: 12,
                  textAlign: 'left',
                  textBaseline: 'middle',
                  // fontWeight: 'Medium',
                  fontFamily:'Roboto',
                },
                name: 'title',
              });

              group.addShape('text', {
                attrs: {
                  text: cfg.name,
                  x: -60,
                  y: 15,
                  fill: '#000000',
                  fontSize: 16,
                  textAlign: 'left',
                  textBaseline: 'middle',
                  fontWeight: 'Bold',
                  fontFamily:'ABC',
                },
                name: 'text-shape',
              });

              return rect;
            },

          },
          'single-node',
      );

      const container = document.getElementById('graph-container');
      const width = container.scrollWidth;
      const height = container.scrollHeight || 800;
      this.container_width = width;
      this.container_height = height;
      graph = new G6.Graph({
        container: 'mountNode',
        width,
        height,


        layout: {
          type: 'dagre',
          rankdir: 'LR',
          align: 'DL',
          // nodesepFunc: (d) => {
          //   if (d.id === '3') {
          //     return 500;
          //   }
          //   return 50;
          // },
          ranksep: 70,
          controlPoints: true,
        },
        defaultNode: {
          type: 'basic',
        },
        defaultEdge: {
          type: 'polyline',
          style: {
            radius: 20,
            offset: 45,
            endArrow: true,
            lineWidth: 2,
            stroke: '#C2C8D5',
          },
        },
        nodeStateStyles: {
          selected: {
            // stroke: '#000000',
            lineWidth: 6,
          },
        },
        modes: {
          default: [
            'drag-canvas',
            'zoom-canvas',
            'click-select',
            // {
            //   type: 'tooltip',
            //   formatText(model) {
            //     const cfg = model.conf;
            //     const text = [];
            //     cfg.forEach((row) => {
            //       text.push(row.label + ':' + row.value + '<br>');
            //     });
            //     return text.join('\n');
            //   },
            //   offset: 30,
            // },
          ],
        },
        fitView: true,
        // fitCenter:true,
      });

      graph.data(this._nodeData); // 读取 Step 2 中的数据源到图上
      graph.render(); // 渲染图

/*      graph.on('node:mouseenter', (e) => {
        const nodeItem = e.item;
        // 设置目标节点的 hover 状态 为 true
        graph.setItemState(nodeItem, 'hover', true);
      });
      // 监听鼠标离开节点
      graph.on('node:mouseleave', (e) => {
        const nodeItem = e.item;
        // 设置目标节点的 hover 状态 false
        graph.setItemState(nodeItem, 'hover', false);
      });
      // 监听鼠标点击节点*/

      graph.on('node:click', (e) => {
        // 先将所有当前有 click 状态的节点的 click 状态置为 false
        const clickNodes = graph.findAllByState('node', 'click');
        clickNodes.forEach((cn) => {
          graph.setItemState(cn, 'click', false);
        });
        const nodeItem = e.item;
        // 设置目标节点的 click 状态 为 true
        graph.setItemState(nodeItem, 'click', true);
        this.handleNodeClick(nodeItem);
      });
      // 监听鼠标点击节点
      graph.on('edge:click', (e) => {
        // 先将所有当前有 click 状态的边的 click 状态置为 false
        const clickEdges = graph.findAllByState('edge', 'click');
        clickEdges.forEach((ce) => {
          graph.setItemState(ce, 'click', false);
        });
        const edgeItem = e.item;
        // 设置目标边的 click 状态 为 true
        graph.setItemState(edgeItem, 'click', true);
      });
      this.graph_init_zoom();
    },
    graph_init_zoom(){
      setTimeout(() => {
        // graph.focusItem(graph.findById('0'),true,{
        //   easing: 'easeCubic',
        //   duration: 500,
        // });
        // graph.zoom(2, { x: 0, y: this.container_height/2 }, true, {
        //   duration: 100,
        // });
      }, 1000);
    },
    handleNodeClick(_item) {
      const click_item = _item;
      // animately move the graph to focus on the item.
      // the second parameter controlls whether move with animation, the third parameter is the animate configuration
      graph.focusItem(click_item, true, {
        easing: 'easeCubic',
        duration: 500,
      });
      // console.log(_item._cfg.model.url);
      if(_item._cfg.model.url){
        // console.log(_item._cfg.url);
        window.open(_item._cfg.model.url);
      }
    }

  },
  props:{
    _nodeData:{
      type:Object,
      default:null
    }
  },



}
</script>

<style>
#diagram-container{
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-evenly;
  height: fit-content;
  gap: 20px;
}

#graph-container {
  /*border-right: solid #6f6f6f 2px;*/
  /*border-end-end-radius: 2px;*/
  /*border-radius: 6px;*/
  width: 95vw;
  /*padding: 5px;*/
  /*border: solid rgba(111,111,111,0.6) 2px;*/
  /*border-radius: 7px;*/
}

/*#ui-container{*/
/*  !*padding: 5px;*!*/
/*  width: 45%;*/
/*  border: solid rgba(111,111,111,0.6) 2px;*/
/*  border-radius: 7px;*/
/*}*/
#border-container{
  background: #6f6f6f;
  width: 1px;
  opacity: 0.5;
}

.g6-tooltip {
  border-radius: 6px;
  font-size: 12px;
  color: #fff;
  background-color: #000;
  padding: 2px 8px;
  text-align: center;
}
</style>
