<template>
  <div class='welcomeScreen'>
    <nav-bar></nav-bar>
    <div class="header">
      <img v-if="isMobile" :src="require('../assets/header/header_minified_mobile.png')">
      <img v-else :src="require('../assets/header/header_minified.png')">
    </div>
    <div class="hero-banner">
      <main-title class="title" :title="title" ></main-title>
      <div class="workflow-button">
      <main-button text="Get Your Workflow" url="/category"></main-button>
      </div>
    </div>
    <div class="intro-title" >
      <img :src="require('../assets/titleImage/effortless.png')">
      <div class="intro-subtitle">
        <span>
          These tools are provided by third parties. You have to purchase respectively.
        </span>
      </div>
    </div>
    <div class="introduction">
      <div class="intro-container-left" id="art-intro-container">
        <div class="intro-content">
          <div class="intro-content-image">
            <img
                :src="require('../assets/showcase/sunset.jpg')"
            >
          </div>
          <main-button class="introduction-button" text="Draw Art" color-style="#e63946"></main-button>
        </div>
      </div>
      <div class="intro-container-left" id="music-intro-container">
        <div class="intro-content">
          <div class="intro-content-image">
            <img
                :src="require('../assets/showcase/casset.jpg')"
            >
          </div>
          <main-button class="introduction-button" text="Make Music" color-style="#1d3557"></main-button>
        </div>
      </div>
      <div class="intro-container-left" id="literature-intro-container">
        <div class="intro-content">
          <div class="intro-content-image">
            <img
                :src="require('../assets/showcase/typewriter.jpg')"
            >
          </div>
          <main-button class="introduction-button" text="Write Story" color-style="#ffb703"></main-button>
        </div>
      </div>
    </div>
    <div class="game-container">
      <div class="game-title">
        <img :src="require('../assets/titleImage/only.png')">
        <div class="game-subtitle">
        <span>
          With AI, we made a game with no artists, musicians or writer whatsoever.
        </span>
        </div>
      </div>
      <div class="game-content">
        <div class="game-content-image">
          <img
              :src="require('../assets/showcase/gamePoster.jpg')"
          >
        </div>
        <main-button class="game-button" text="Play Game" url="https://capsul.itch.io/ai" color-style="#ffb703" ></main-button>
      </div>
    </div>
    <div class="dedicate-tool-container">
      <div class="dedicate-tool-title">
        <img :src="require('../assets/titleImage/dedicated.png')">
        <div class="dedicate-tool-subtitle">
        <span>
          Dedicated game making AI tool by us. One subscription for art, music and story generation.
        </span>
        </div>
      </div>
      <div class="dedicate-tool-button">
        <main-button text="Sign Up to Waitlist" url="/waitlist"></main-button>
      </div>
    </div>


  </div>
</template>

<script>

import MainTitle from "@/components/MainTitle";
import NavBar from "@/components/navbar";
import MainButton from "@/components/MainButton";
export default {
  name: "WelcomeScreen",
  components: { MainButton, NavBar, MainTitle},
  data() {
    return {
      title:"AI Empowers Game Making"
    }
  },
  computed: {
    isMobile: function() {
      //calculate if is mobile
      return window.innerWidth < 768;
    }
  },
  methods: {
  }
}


</script>

<style scoped>
.game-content-image>img{
  max-width: 80vw;
  border-radius: 20px;
}

.game-content{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dedicate-tool-container,.game-container{
  margin-bottom: 60px;
}

.dedicate-tool-button{
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.intro-title,.dedicate-tool-title,.game-title{
  display: flex;flex-direction: column ;align-items: center; margin-bottom: 40px;
}
.intro-title>img,.dedicate-tool-title>img,.game-title>img{
  max-width: 480px;margin-bottom: 40px;
}
.intro-title>.intro-subtitle,.dedicate-tool-title>.dedicate-tool-subtitle
,.game-title>.game-subtitle{
  max-width: 60vw;text-align: center;
}


.header{
  display: flex;
  justify-content: center;
  margin-top: 40px;

}
.header>img{
  max-width:100% ;
}

.intro-content{
  position:relative;
  /*width:30vw;*/
}
.introduction-text{
  position: absolute;
  left: 0px;
  bottom: 0px;
}
.introduction-button{
  position: relative;

  left: 40px;
  bottom: 20px;
}
.intro-content-image>img{
  border: white solid 1px;
  border-radius: 40px;
  width: 180px;
}

.introduction{
  display: flex;
  flex-direction: row;
  padding: 0px 16vw;
  margin-bottom: 120px;
}

@media only screen and (max-width: 749px){
  .introduction{
    display: flex;
    flex-direction: column;
    padding: 0px 10vw;
  }
  .intro-container-left{
    margin: auto;
  }
  .intro-content-image>img{
    border: white solid 1px;
    border-radius: 40px;
    width: 260px;
  }
  .header>img{
    width: 600px ;
  }

  .intro-title{
    display: flex;flex-direction: column ;align-items: center; margin-bottom: 40px;
  }
  .intro-title>img{
    max-width: 90vw;margin-bottom: 40px;
  }
  .dedicate-tool-title>img{
    max-width: 90vw;margin-bottom: 40px;

  }
  .game-title>img{
    max-width: 90vw;margin-bottom: 40px;

  }

  .intro-title>.intro-subtitle{
    max-width: 90vw;text-align: center;
  }
  .game-content-image>img{
    max-width: 90vw;
  }

}





.intro-container-left{
  margin-right: auto;
}
.intro-container-right{
  margin-left: auto;
}

.title{
  margin-top: 10vh;
  font-size: 60px;
  /*font-family: 'Major Mono Display', monospace;*/
}
.hero-banner{
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  margin-bottom: 20vh;
}


</style>
