import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/WelcomeScreen.vue";
import NotFound from "@/views/NotFound";
import categoryView from "@/views/CategoryView";
import resultView from "@/views/ResultView";
import waitlistSignup from "@/views/WaitlistSignup";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/category",
        name: "Category",
        component: categoryView,
    },
    {
        path: "/question/:type",
        name: "Question",
        component: NotFound,
    },
    {

      path: "/result",
      name: "Result",
      component: resultView,
    },
    {

        path: "/waitlist",
        name: "Waitlist",
        component: waitlistSignup,
    },
    {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: NotFound,
    }

];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
