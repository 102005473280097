<template>
  <div class='title'>
            <span class="titleText">
                {{ title }}
            </span>
  </div>
</template>

<script>
export default {
  name: "MainTitle",
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.title{
  /* margin-left: 9vh; */
  /* padding: 10vh 0 20px 0; */
  /* text-align: center; */
  font-size: 3.5vw;
  max-font-size: 60px;
  text-align: center;
}
</style>
