<template>
  <div class="resultScreen">
    <nav-bar></nav-bar>
    <diagram-viewer :_node-data="diagramData"></diagram-viewer>

  </div>
</template>

<script>

import NavBar from "@/components/navbar";
import DiagramViewer from "@/components/DiagramViewer";
export default {
  name: "ResultView",
  components: {
    DiagramViewer,
    NavBar

  },
  data(){
    return{
      diagramData:{
        nodes: [
            {
              id: '0',
              type: 'text-gen',
              name: 'AI Dungeon',
              output: 'World Designs, Character Designs, Factions, And First-person Stories ',
              url:'https://play.aidungeon.io/',
            },
          {
            id: '1',
            type: 'text-gen',
            name: 'GPT-J',
            output: 'Story',
            url:'https://huggingface.co/EleutherAI/gpt-j-6B',
          },
          {
            id: '2',
            type: 'text-gen',
            name: 'Novel AI',
            output: 'Story in Novel Form',
            url:'https://novelai.net/',
          },
          {
            id: '3',
            type: 'image-gen',
            name: 'Art Breeder',
            output: 'Character Art, Background Art, And World Art',
            url:'https://www.artbreeder.com/',
          },
          {
            id: '4',
            type: 'audio-gen',
            name: 'AIVA',
            output: 'BGM, Editable Composition',
            url:'https://aiva.ai/',
          },
          {
            id: '5',
            type: 'human-gen',
            name: 'Ableton Live',
            output: 'Self-mixed Music',
          },
          {
            id: '6',
            type: 'image-gen',
            name: 'NightCafe',
            output: 'Image Art',
            url:'https://nightcafe.studio/',

          },
          {
            id: '7',
            type: 'image-gen',
            name: 'DiscoDiffusion',
            output: 'Image Art',
            url:'https://colab.research.google.com/github/alembics/disco-diffusion/blob/main/Disco_Diffusion.ipynb',
          },
          {
            id: '8',
            type: 'image-gen',
            name: 'Mid Journey',
            output: 'Image Art',
            url:'https://www.midjourney.com/',

          },
          {
            id: '9',
            type: 'human-gen',
            name: 'Photoshop',
            output: 'Properly Processed Image',
          },

          ],
          edges: [
            {
              source: '0',
              target: '1',
            },
            {
              source: '0',
              target: '2',
            },
            {
              source: '1',
              target: '2',
            },
            {
              source: '0',
              target: '3',
            },
            {
              source: '0',
              target: '4',
            },
            {
              source: '4',
              target: '5',
            },
            {
              source: '3',
              target: '6',
            },
            {
              source: '3',
              target: '7',
            },
            {
              source: '3',
              target: '8',
            },
            {
              source: '6',
              target: '9',
            },
            {
              source: '7',
              target: '9',
            },
            {
              source: '8',
              target: '9',
            },
          ],
        },
        container_width: 0,
        container_height: 0,

      }
    }


}
</script>

<style scoped>
  .resultScreen{
    background-color: rgba(255, 255, 255, 0.65);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  @media (max-width: 768px) {
    .resultScreen{
    }
  }
</style>
