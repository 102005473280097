<template>
<div>
  <nav-bar></nav-bar>
  <iframe src="/Signup.html" style="width: 100vw;height: 90vh;border: transparent"></iframe>
</div>
</template>
<script>
import NavBar from "@/components/navbar";
export default {
  name: "WaitlistSignup",
  components: {NavBar}
}
</script>
<style scoped>
</style>
