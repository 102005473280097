<template>
  <button ref="button" class='mainButton' @click="computedClickEvent" v-bind:disabled="disabled" v-bind:style="{'background-color': colorStyle}">
    {{ text }}
  </button>
</template>

<script>
import './css/MainButton.css'

export default {
  name: "MainButton",
  props: {
    text: {
      type: String,
      default: 'Button Text'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * @deprecated The method should not be used, use clickEvent or url instead
     */
    buttonBehaviourData: {
      type: Object,
      default: () => ({})
    },
    /**
     * @deprecated The method should not be used, use clickEvent or url instead
     */
    buttonBehaviourType: {
      type: String,
      default: 'redirect'
    },
    clickEvent: {
      type: Function,
      default: undefined
    },
    colorStyle: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    }
  },
  beforeCreate() {
    if (this.clickEvent !== undefined && this.url !== '') {
      console.warn('Having a click event and a url at the same time is not recommended. I will do my best!');
    }
  },
  methods:{
    computedClickEvent:function (){

        if(this.url !== ''){
          if(this.url.startsWith('/')){
            this.$router.push(this.url);
          }else{
            window.location = this.url;
          }
        }

      }
    }

}
</script>


<style scoped>

</style>
